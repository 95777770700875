/* You can add global styles to this file, and also import other style files */
@import 'bootstrap/scss/bootstrap';
@import 'spinner';
@import 'utils';
@import 'rtl';
@import 'responsive';

@media print {
    .breadcrumb {
        display: none !important;
    }
}
